<template>
  <component
    :is="component"
    class="b-button box-border inline-flex items-center text-base leading-normal font-medium cursor-pointer transition duration-200"
    :class="[style, blockStyle, roundedStyle, {'icon-alone': $slots.icon}]"
    :href="linkHref"
    :to="to"
    :type="buttonType"
    :disabled="loading || disabled"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot name="icon" />
    <img
      v-if="icon"
      :src="icon"
      alt="icon"
      class="h-4 w-4 m-h-4 m-w-4"
      :class="iconClass"
      aria-hidden="true"
      data-test="icon"
    >
    <span
      v-if="loading && withLoadingText"
      class="flex"
    >
      <slot
        name="loadingText"
      />
      <span
        class="block m-auto spinner ml-2 rounded-full w-4 h-4 border-solid"
      />
    </span>
    <slot
      v-else
    />
  </component>
</template>

<script>
import { COLORS, SIZES, VARIANTS } from './BButton.constants'

export default {
  name: 'BButton',
  props: {
    /**
     * Allow to render the button as custom component (e.g. router-link)
     */
    as: {
      type: String,
      default: null,
    },
    /**
     * This prop is delegated to the `type` attribute of the internal `<button>` element
     */
    type: {
      type: String,
      default: 'button',
      validator: value => ['button', 'reset', 'submit'].includes(value),
    },
    /**
     * This prop makes element <a> link and is delegated to `href` attribute of the `<a>` element
     */
    href: {
      type: String,
      default: null,
    },
    /**
     * This prop makes element <inertia-link> and is delegated to `href` attribute of the `<inertia-link>` element
     */
    to: {
      type: [String, Object],
      default: null,
    },
    variant: {
      type: String,
      default: VARIANTS.light,
      validator: value => Object.values(VARIANTS).includes(value),
    },
    /**
     * Button color. Possible values: `base`, `primary`, `danger`,
     */
    color: {
      type: String,
      default: COLORS.primary,
      validator: value => Object.values(COLORS).includes(value),
    },
    /**
     * Button size. Possible values: `sm`, `base`, `lg`
     */
    size: {
      type: String,
      default: 'base',
      validator: value => Object.values(SIZES).includes(value),
    },
    /**
     * Set button width to 100%
     */
    block: {
      type: Boolean,
      default: false,
    },
    /**
     * Renders `<button>` with disabled attribute and disabled style
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Src attribute of the icon displayed at the left of the button
     */
    icon: {
      type: String,
      default: '',
    },
    /**
     * Icon is placed before or after button's title
     */
    iconPosition: {
      type: String,
      default: 'start',
      validate: val => ['start', 'end'].includes(val),
    },
    /**
     * Renders `<button>` with disabled attribute and loading spinner
     */
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * Set button width to 100%
     */
    rounded: {
      type: Boolean,
      default: false,
    },

    isAdaptive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    style() {
      const classes = []

      classes.push(`color-${this.color}`)
      classes.push(`size-${this.size}`)
      classes.push(`variant-${this.variant}`)

      if (this.disabled) {
        classes.push('disabled')
      }

      if (this.isAdaptive) {
        classes.push('adaptive')
      }

      if (this.loading) {
        classes.push('opacity-50 cursor-wait')
      }

      return classes
    },
    blockStyle() {
      return this.block ? 'w-full justify-center' : ''
    },
    roundedStyle() {
      return this.rounded ? 'rounded-full' : 'rounded'
    },
    iconClass() {
      return [
        `icon-${this.iconPosition}`,
        `icon-${this.size}`,
      ]
    },
    component() {
      if (this.as) {
        return this.as
      }

      if (this.isLink) {
        return 'a'
      }

      return this.isInertiaLink ? 'inertia-link' : 'button'
    },
    isLink() {
      return !!this.href
    },
    isInertiaLink() {
      return !this.as && !!this.to
    },
    buttonType() {
      return this.component === 'button'
        ? (this.type || 'button')
        : null
    },
    linkHref() {
      if (this.isLink && !this.disabled) {
        return this.href
      }

      return (this.isInertiaLink) ? this.to : null
    },
    withLoadingText() {
      return !!this.$slots.loadingText
    },
  },
}
</script>

<style scoped>
.b-button {
  &.disabled {
    &:not(.variant-text) {
      background: var(--gray-500) !important;
      border-color: var(--gray-500) !important;
      color: var(--light) !important;
    }

    color: var(--gray-500) !important;
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.variant-fill {
  color: var(--contrast-text-color, var(--light));

  &.color-primary {
    background: var(--primary);

    &:hover {
      background: rgba(var(--primary-rgb), 0.8);
    }

    &:active {
      background: rgba(var(--primary-rgb), 0.7);
    }
  }

  &.color-danger {
    background: var(--danger);

    &:hover {
      background: rgba(var(--danger-rgb), 0.8);
    }

    &:active {
      background: rgba(var(--danger-rgb), 0.7);
    }
  }

  &.color-base {
    background: var(--dark);

    &:hover {
      background: rgba(var(--dark-rgb), 0.8);
    }

    &:active {
      background: rgba(var(--dark-rgb), 0.7);
    }
  }

  &.color-inverted {
    background: var(--light);
    color: var(--black);

    &:hover {
      background: rgba(var(--light-rgb), 0.8);
    }

    &:active {
      background: rgba(var(--light-rgb), 0.7);
    }
  }
}

.variant-light {
  &.color-primary {
    background: rgba(var(--primary-rgb), 0.1);
    color: var(--primary);

    &:hover {
      background: rgba(var(--primary-rgb), 0.15);
    }

    &:active {
      background: rgba(var(--primary-rgb), 0.3);
    }
  }

  &.color-danger {
    background: rgba(var(--danger-rgb), 0.1);
    color: var(--danger);

    &:hover {
      background: rgba(var(--danger-rgb), 0.15);
    }

    &:active {
      background: rgba(var(--danger-rgb), 0.3);
    }
  }

  &.color-base {
    background: rgba(var(--dark-rgb), 0.05);
    color: var(--dark);

    &:hover {
      background: rgba(var(--dark-rgb), 0.1);
    }

    &:active {
      background: rgba(var(--dark-rgb), 0.15);
    }
  }

  &.color-inverted {
    background: rgba(var(--light-rgb), 0.1);
    color: var(--light);

    &:hover {
      background: rgba(var(--light-rgb), 0.15);
    }

    &:active {
      background: rgba(var(--light-rgb), 0.3);
    }
  }
}

.variant-text {
  &.color-primary {
    color: var(--primary);

    &:hover {
      color: rgba(var(--primary-rgb), 0.8);
    }

    &:active {
      color: rgba(var(--primary-rgb), 0.7);
    }
  }

  &.color-danger {
    color: var(--danger);

    &:hover {
      color: rgba(var(--danger-rgb), 0.8);
    }

    &:active {
      color: rgba(var(--danger-rgb), 0.7);
    }
  }

  &.color-base {
    color: var(--dark);

    &:hover {
      color: rgba(var(--dark-rgb), 0.8);
    }

    &:active {
      color: rgba(var(--dark-rgb), 0.7);
    }
  }

  &.color-inverted {
    color: var(--light);

    &:hover {
      color: rgba(var(--light-rgb), 0.8);
    }

    &:active {
      color: rgba(var(--light-rgb), 0.7);
    }
  }
}

.icon-alone {
  @apply items-center justify-center;
}

.size-sm {
  @apply text-sm leading-4;

  &:not(.variant-text, .icon-alone) {
    min-height: 2rem;
    padding: 0.5rem 0.75rem;

    &.adaptive {
      @apply h-8 px-3 py-2 text-sm;

      @media screen and (min-width: 640px) {
        & {
          @apply h-10 px-4 text-base;
        }
      }
    }
  }

  &.icon-alone {
    @apply h-8 w-8;
  }
}

.size-base {
  &:not(.variant-text, .icon-alone) {
    min-height: 2.5rem;
    padding: 0.5rem 1rem;
  }

  &.icon-alone {
    @apply h-10 w-10;
  }
}

.size-lg {
  @apply text-lg leading-6;

  &:not(.variant-text, .icon-alone) {
    min-height: 3rem;
    padding: 0.75rem 1.5rem;
  }

  &.icon-alone {
    @apply h-12 w-12;
  }
}

.icon-start {
  &.icon-sm {
    @apply mr-1;
  }

  &.icon-base {
    @apply mr-2;
  }

  &.icon-lg {
    @apply mr-3;
  }
}

.icon-end {
  @apply order-last;

  &.icon-sm {
    @apply ml-1;
  }

  &.icon-base {
    @apply ml-2;
  }

  &.icon-lg {
    @apply ml-3;
  }
}

.dark {
  .variant-fill {
    &.color-base {
      background: var(--light);
      color: var(--black);

      &:hover {
        background: rgba(var(--light-rgb), 0.8);
      }

      &:active {
        background: rgba(var(--light-rgb), 0.7);
      }
    }

    &.color-inverted {
      background: var(--dark);
      color: var(--light);

      &:hover {
        background: rgba(var(--dark-rgb), 0.8);
      }

      &:active {
        background: rgba(var(--dark-rgb), 0.7);
      }
    }
  }

  .variant-light {
    &.color-primary {
      background: rgba(var(--primary-rgb), 0.24);
      color: var(--primary);

      &:hover {
        background: rgba(var(--primary-rgb), 0.3);
      }

      &:active {
        background: rgba(var(--primary-rgb), 0.4);
      }
    }

    &.color-danger {
      background: rgba(var(--danger-rgb), 0.24);
      color: var(--danger);

      &:hover {
        background: rgba(var(--danger-rgb), 0.3);
      }

      &:active {
        background: rgba(var(--danger-rgb), 0.4);
      }
    }

    &.color-base {
      background: rgba(var(--light-rgb), 0.1);
      color: var(--gray-100);

      &:hover {
        background: rgba(var(--light-rgb), 0.15);
      }

      &:active {
        background: rgba(var(--light-rgb), 0.3);
      }
    }

    &.color-inverted {
      background: rgba(var(--dark-rgb), 0.05);
      color: var(--dark);

      &:hover {
        background: rgba(var(--dark-rgb), 0.1);
      }

      &:active {
        background: rgba(var(--dark-rgb), 0.15);
      }
    }
  }

  .variant-text {
    &.color-base {
      color: var(--light);

      &:hover {
        background: rgba(var(--light-rgb), 0.1);
      }

      &:active {
        background: rgba(var(--light-rgb), 0.15);
      }
    }

    &.color-inverted {
      color: var(--dark);

      &:hover {
        background: rgba(var(--dark-rgb), 0.1);
      }

      &:active {
        background: rgba(var(--dark-rgb), 0.15);
      }
    }
  }
}

.spinner {
  border: 2px solid currentColor;
  border-right-color: transparent;
  animation: rotate 0.9s linear infinite;
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}
</style>
