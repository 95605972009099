export const COLORS = {
  primary: 'primary',
  danger: 'danger',
  base: 'base',
  inverted: 'inverted',
}

export const VARIANTS = {
  fill: 'fill',
  light: 'light',
  text: 'text',
}

export const SIZES = {
  sm: 'sm',
  base: 'base',
  lg: 'lg',
}
